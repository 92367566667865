import "./App.css";
import { Container, Row, Button, Col } from "react-bootstrap";
import MediaIcons from "./components/MediaIcons/MediaIcons";
import Logo from "./components/svgLogo/svgLogo";
import classes from "./App.module.css";
import Address from "./components/Address/Address";
import React, { Component } from "react";

export class App extends Component {
	state = {
		language: "中文",
	};

	languageToggleHandler = (value) => {
		this.setState({ language: value });
	};

	render() {
		let ToggleButton =
			this.state.language === "English" ? (
				<Button
					onClick={(value) => this.languageToggleHandler("中文")}
					className={"m-2"}
				>
					中文
				</Button>
			) : (
				<Button
					onClick={(value) => this.languageToggleHandler("English")}
					className={" m-2"}
				>
					English
				</Button>
			);

		return (
			<div className="App">
				<Container fluid className={"text-align-center p-3"}>
					{ToggleButton}

					<Row className={"align-content-center mt-3"}>
						<h1 className={classes.Heading}>
							{this.state.language === "English"
								? "Little Canada English School"
								: "私立小加拿大短期補習班"}
						</h1>
						<Logo />
						<br />
						{this.state.language === "English" ? (
							<h3 className={classes.Heading}>
								Page currently under construction
							</h3>
						) : (
							<h3 className={classes.Heading}>網頁更新中</h3>
						)}
					</Row>
					<Row className="justify-content-center pt-3 pb-5">
						<Col className="pt-3 pt-md-0" xs={12} sm={12} md={6} lg={6} xl={6}>
							<MediaIcons language={this.state.language} />
						</Col>
						<Col className="pt-3 pt-md-0" xs={12} sm={12} md={6} lg={6} xl={6}>
							<Address language={this.state.language} />
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}

export default App;
