import React from "react";
import { Envelope, Facebook, TelephoneFill } from "react-bootstrap-icons";
import { Row, Col, Container } from "react-bootstrap";
import classes from "./MediaIcons.module.css";

const MediaIcons = (props) => {
	return (
		<>
			<u>
				{props.language === "English" ? <h3>Contact Us</h3> : <h3>聯絡我們</h3>}
			</u>
			<a
				target="_blank"
				rel="noreferrer"
				href="https://www.facebook.com/littlecanada.english"
				className={"p-2"}
			>
				<Facebook color="#FFFFFF" size={50} />
			</a>
			<a
				target="_blank"
				rel="noreferrer"
				href="mailto:info@littlecanadalanguagesschool.com"
				className={"p-2"}
			>
				<Envelope color="#FFFFFF" size={50} />
			</a>
			<a
				target="_blank"
				rel="noreferrer"
				href="tel:00982398406"
				className={"p-2"}
			>
				<TelephoneFill color="#FFFFFF" size={50} />
			</a>
		</>
	);
};

export default MediaIcons;
