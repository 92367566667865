import React from "react";
import { Col } from "bootstrap";

function Address(props) {
	return (
		<>
			{props.language === "English" ? (
				<u>
					<h3>Find us at :</h3>
				</u>
			) : (
				<h4>找到我們 :</h4>
			)}
			{props.language === "English" ? (
				<>
					<h6>2F, 270 Linsen Road, Hualien City, Hualien County</h6>
					<h6>97064, Taiwan </h6>
				</>
			) : (
				<p>林森路270號2樓</p>
			)}
		</>
	);
}

export default Address;
